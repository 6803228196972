.terms-heading-list {
    color: #004993;
  }
  .list-clause-alpha {
    font-weight: bold;
  }
  /* #term-conditon-heading{
      font-size: medium;
  } */
  @media screen and (max-width: 710px) {
    .term-condition-main-container .term-conditon-heading {
      font-size: 20px;
    }
  }
  
  @media screen and (max-width: 512px) {
    .term-condition-main-container .term-conditon-heading {
      font-size: 1rem;
    }
    .term-conditon-sub-heading {
      font-size: 14px;
    }
    .term-conditon-txt {
      font-size: 14px;
    }
    .term-conditon-txt-version {
      font-size: 14px;
    }
  }
  
  .term-condition-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    overflow-x: hidden !important;
    /* padding: 10px; */
  }
  .term-conditon-heading {
    color: #6f6f6f;
    font-size: 28px;
  }
  .term-conditon-sub-heading {
    color: #004993;
  }
  .term-conditon-txt {
    color: #004993;
    text-decoration: underline;
  }
  .term-conditon-txt-version {
  }
  