/* input[type="date"]:in-range::-webkit-datetime-edit-year-field, */
/* input[type="date"]:in-range::-webkit-datetime-edit-month-field, */
/* input[type="date"]:in-range::-webkit-datetime-edit-day-field, */
/* input[type="date"]:in-range::-webkit-datetime-edit-text {
  color: transparent;
  background-color: transparent;
} */

@import url("https://p.typekit.net/p.css?s=1&k=gll4mhg&ht=tk&f=2005.2006.2007.2008.2009.2011.2012&a=3099470&app=typekit&e=css");

@font-face {
  font-family: "museo-sans";
  src: url("https://use.typekit.net/af/620bf8/00000000000000000000e7fe/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/620bf8/00000000000000000000e7fe/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/620bf8/00000000000000000000e7fe/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: "museo-sans";
  src: url("https://use.typekit.net/af/5cca6d/00000000000000000000e802/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/5cca6d/00000000000000000000e802/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/5cca6d/00000000000000000000e802/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: "museo-sans";
  src: url("https://use.typekit.net/af/a28b50/00000000000000000000e803/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/a28b50/00000000000000000000e803/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/a28b50/00000000000000000000e803/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: "museo-sans";
  src: url("https://use.typekit.net/af/c2d3de/00000000000000000000e804/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/c2d3de/00000000000000000000e804/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/c2d3de/00000000000000000000e804/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 500;
  font-stretch: normal;
}

@font-face {
  font-family: "museo-sans";
  src: url("https://use.typekit.net/af/e3ca36/00000000000000000000e805/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/e3ca36/00000000000000000000e805/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/e3ca36/00000000000000000000e805/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "museo-sans";
  src: url("https://use.typekit.net/af/9cf49e/00000000000000000000e807/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/9cf49e/00000000000000000000e807/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3")
      format("woff"),
    url("https://use.typekit.net/af/9cf49e/00000000000000000000e807/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
}

@font-face {
  font-family: "museo-sans";
  src: url("https://use.typekit.net/af/27c143/00000000000000000000e808/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/27c143/00000000000000000000e808/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("woff"),
    url("https://use.typekit.net/af/27c143/00000000000000000000e808/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 900;
  font-stretch: normal;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  /* //display: none; */
  /* //-webkit-appearance: none; */
  width: 15px;
  height: 15px;
  background-image: url("assets/images/icons/awesome-calendar-alt.svg");
}

html {
  font-family: museo-sans, sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

.swal2-styled.swal2-confirm {
  background-color: #004993 !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

/* input::-webkit-textfield-decoration-container {
  display: none;
} */

.tk-museo-sans {
  font-family: "museo-sans", sans-serif;
}

.css-1r8mrri-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0 !important;
  height: auto !important;
}

/* .css-dd2h8b-MuiAutocomplete-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-endAdornment {
} */

.css-dd2h8b-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 9 !important;
  /* overflow: hidden !important; */
}

/* .css-1yyjtgv-MuiAutocomplete-root {
  width: 100% !important;
} */

::-webkit-input-placeholder {
  color: #a6a6a6;
}

input::placeholder {
  color: #a6a6a6;
}

.css-b62m3t-container {
  width: 98%;
}

.react-datepicker-wrapper {
  padding: 0 !important;
  width: max-content !important;
}

.react-datepicker-wrapper {
  display: block !important;
  padding: 0 !important;
  border: 0 !important;
  padding-top: 11px !important;
  padding-left: 10px !important;
  padding-left: 25px !important;
}

.react-datepicker__input-container {
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}

.date-picker {
  width: 100%;
  padding: 5px 8px;
  margin-top: -15px;
  margin-left: -10px;
  /* margin-left: 10px !important; */
  border-radius: 5px;
  border: 1px solid rgb(166, 166, 166);
}

/* .css-1s2u09g-control {
  height: 40px !important;
} */

.css-g1d714-ValueContainer {
  height: 40px !important;
  overflow-y: scroll !important;
}

.css-g1d714-ValueContainer::-webkit-scrollbar {
  display: none !important;
}

.css-12jo7m5 {
  padding: 0 !important;
  padding-left: 2px !important;
}

@media (max-width: 375px) {
  .css-b62m3t-container {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .css-1yyjtgv-MuiAutocomplete-root {
    width: 300px !important;
  }
}
